.App {
  font-family: sans-serif;
  text-align: center;
  background-color: #131a2f;
}

.mobile {
  display: none;
}
a {
  color: inherit;
}

.ground {
  border-radius: 40px;
  padding: 25px 15px;
  background-color: #4a5573;
  height: 460px;
  width: 390px;
  box-shadow: 0 12px 12px rgb(0, 0, 0);
  text-align: center;
  margin: 193px 120px;
  position: relative;
  z-index: 100;
}

.top {
  display: flex;
  height: 50px;
}

.windowComponents {
  opacity: 0.53;
}

.main {
  width: 360px;
  height: 355px;
  background: #959fba;
  box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.16);
  border-radius: 26px;
  margin: 1px 1px;
}

.buttomBase {
  width: 269px;
  height: 71px;
  border-radius: 12px;
  background: transparent;
  /* Note: currently only Safari supports backdrop-filter */
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.44);
  margin: -240px 55px;
  position: relative;
}

.buttonTop {
  width: 267px;
  height: 71px;
  border-radius: 9px;
  background: #9f6161;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  margin: 175px 61px;
  position: absolute;
}

.holyScreen {
  width: 690px;
  height: 599px;
  border-radius: 46px;
  background: #fff;
  opacity: 0.03;
  margin: -478px -28px;
}

.logo {
  color: #fff;
  font-family: "Space Mono";
  opacity: 0.2;
}

.btnbase {
  width: 152px;
  height: 41px;
  border-radius: 6px;
  background: transparent;
  backdrop-filter: blur(3.327169895172119px);
  --webkit-backdrop-filter: blur(3.327169895172119px);
  background-color: rgba(255, 255, 255, 0.36);
  position: relative;
}

.btntop {
  width: 148px;
  height: 42px;
  border-radius: 6px;
  background: #131a2f;
  color: #fff;
  margin: -36px 6px;
  position: absolute;
}

.btntop p {
  margin: 12px;
  opacity: 0.3;
}

.progressbar {
  width: 378px;
  height: 41px;
  border-radius: 20.5px;
  background: #959fba;
  position: relative;
  margin: 300px 110px;
}

.progressstatus {
  width: 323px;
  height: 33px;
  border-radius: 16.5px;
  background: #2b3657;
  margin: 4px 4px 5px;
  z-index: 100;
  position: absolute;
}

.pointer {
  width: 72.5px;
  height: 116px;
  margin: -195px 10px;
  position: absolute;
  z-index: 100;
  opacity: "0%";
}

.star path {
  background: transparent;
}

.starsContainer {
  width: 378px;
  position: absolute;
  z-index: 100;
  margin: -410px 110px;
}

.firstSection {
  margin: 293px 130px;
  position: relative;
  z-index: 100;
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 59px;
  text-align: left;
  color: #fff;
}

.lineTwo {
  color: #9f6161;
}

.secondsection {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 59px;
  text-align: left;
  color: #fff;
  opacity: 0;
  margin-top: 100px;
}
.secondsection .secondLabel {
  color: #9f6161;
}

.screenInSecond {
  width: 688px;
  height: 59px;
  border-radius: 2px;
  background: #fff;
  opacity: 0.02;
}

.section2Title {
  margin-left: 740px;
}

.section2Title p {
  text-align: justify;
  color: #fff;
  font-size: 19px;
  font-weight: 100;
  line-height: 33px;
  opacity: 0.8;
  margin-top: 26px;
}
.section2graph1 {
  width: 370px;
  height: 437px;
  border-radius: 40px;
  background: transparent;
  /* box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.16); */
  /* Note: currently only Safari supports backdrop-filter */
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0.05);
  margin-top: -300px;
  margin-left: 15%;
  font-size: 29px;
}
.section3graph1 {
  width: 320px;
  height: 377px;
  border-radius: 40px;
  background: #7b86a2;
  box-shadow: 12px 30px 6px rgba(0, 0, 0, 0.16);
  z-index: 100;
  position: absolute;
  margin-top: -200px;
  margin-left: 15%;
  font-size: 29px;
}

.webTrafficCon {
  margin-left: 70px;
  margin-top: ‒156;
  margin-top: 152px;
  z-index: 100;
}

.webTraffic {
  fill: none;
  stroke: #fff;
  stroke-width: 4px;
  opacity: 0.644;
  margin-left: 122px;
}

.trafficCount {
  margin-left: 20%;
  margin-top: 10%;
}
.trafficCountName {
  margin-left: 20%;
}

.conversionCount {
  margin-left: 20%;
  margin-top: 10%;
}
.conversionCountName {
  margin-left: 20%;
}
.webConversion {
  margin-left: 90px;
  margin-top: ‒156;
  margin-top: 112px;
  z-index: 100;
}

.light {
  width: 198px;
  height: 197px;
  position: absolute;
  z-index: 100;
}

.lightLayout {
  margin-left: 350px;
}

.webarchitecture {
  position: relative;
  margin-right: 420px;
}

.textsection {
  margin-left: 12px;
  position: absolute;
  margin-left: 740px;
  top: 2600px;
}

.textsection p {
  text-align: justify;
  margin-right: 250px;
  margin-top: 30px;
  width: 448px;
  height: 99px;
  font-family: "Helvetica Neue";
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: #fff;
}

.section3title {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 27px;
  text-align: left;
  color: #9f6161;
}

.section3bgtitle {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 46px;
  text-align: left;
  color: #fff;
}

.section3bgSubtitle {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 46px;
  text-align: left;
  color: #fff;
  margin-top: 80px;
}

.fourthSection {
  margin-top: 270px;
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 59px;
  text-align: center;
  color: #fff;
}

.fourthTitle {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 59px;
  text-align: center;
  color: #9f6161;
}

.fourthSubTitle {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 39px;
  text-align: center;
  color: #7b86a2;
  margin-top: 37px;
}

.serviceSection {
  margin-top: 98px;
}

.ads,
.uxui,
.dev {
  width: 344px;
  height: 312px;
  border-radius: 40px;
  background: #131a2f;
  box-shadow: 5px 12px 6px rgba(0, 0, 0, 0.16);
}

.ads svg {
  width: 126.4px;
  height: 104.56px;
  margin-top: 50px;
}

.uxui svg {
  width: 64.31px;
  height: 90.03px;
  margin-top: 60px;
}

.dev svg {
  width: 125.3px;
  height: 83.53px;
  margin-top: 60px;
}

.adsTitle,
.uxuiTitle,
.devTitle {
  margin-top: 25px;
}

.adsTitle,
.adsTitle2,
.uxuiTitle,
.uxuiTitle2,
.devTitle,
.devTitle2 {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 30px;
  text-align: center;
  color: #9f6161;
}

.fifthSection {
  margin-top: 280px;
  color: #fff;
  perspective: 1000px;
}

.fifthSection h3 {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 59px;
  text-align: center;
  color: #fff;
}

.fifthSection h5 {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 39px;
  text-align: center;
  color: #fff;
}

.groundSC {
  margin-top: 110px;
  width: 688px;
  height: 745px;
  position: relative;
}
.tapGround1 {
  position: absolute;
  top: 360px;
}

.section5Title {
  position: absolute;
  top: 750px;
  width: 457px;
}

.section5Title p {
  text-align: justify;
  color: #fff;
  font-size: 19px;
  font-weight: 100;
  line-height: 33px;
  opacity: 0.8;
  margin-top: 26px;
}
.tapGroundLine {
  z-index: 100;
}

.sixthSection {
  margin-top: 379px;
}

.sixthSection h1 {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 59px;
  color: #a4abc0;
  /* Note: currently only Safari supports backdrop-filter */
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: left;
}

.sixthsectionContainer {
  margin-top: 85px;
}

.sevenSection {
  margin-top: 550px;
}

.sevenSection p {
  font-family: "Helvetica Neue";
  font-weight: normal;
  font-size: 25px;
  line-height: 60px;
  text-align: left;
  color: #fff;
  opacity: 0.51;
}

.contactGround {
  width: 530px;
  height: 549px;
  border-radius: 40px;
  background: #131a2f;
  box-shadow: 5px 12px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 64px;
}

.closeTabs {
  margin-right: 426px;
  margin-top: 25px;
}

.avatar {
  width: 192.16px;
  height: 181.32px;
  margin-right: 25px;
  margin-top: 89px;
}

.actionDescription {
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  font-size: 44px;
  text-align: center;
  color: #fff;
  opacity: 0.64;
  margin-top: 23px;
}
.callTActions {
  margin-top: 47px;
}

.messageBtn {
  width: 206px;
  height: 77px;
  border-radius: 18px;
  background: #131a2f;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  margin-left: 45px;
}
.messageIcon,
.callIcon {
  margin-top: 21.1px;
}

.callBtn {
  width: 206px;
  height: 77px;
  border-radius: 18px;
  background: #9f6161;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-right: 45px;
}

body {
  background-color: #131a2f;
  touch-action: pan-y;
}

/* mobile version */
@media only screen and (max-width: 375px) {
  .mobile {
    display: block;
    width: auto;
    height: 70px;
    background: transparent;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 28px;
    text-align: center;
    color: #fff;
    margin: 55px 25px;
    margin-bottom: 8px;
  }
  .mobile p {
    color: #fff;
  }
  .mobile span {
    color: #9f6161;
  }

  .desktop {
    display: none;
  }

  .logo {
    font-weight: normal;
    font-size: 14px;
    text-align: left;
  }

  .ueb,
  .ref {
    display: none;
  }

  .btnbase {
    width: 104px;
    height: 28px;
    border-radius: 6px;
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(3.327169895172119px);
    --webkit-backdrop-filter: blur(3.327169895172119px);
    background-color: rgba(255, 255, 255, 0.36);
    /* (plus shape's fill blended on top as a separate layer with 43.55% opacity) */

    position: relative;
  }

  .btntop {
    width: 103px;
    height: 29px;
    border-radius: 6px;
    background: #131a2f;
    box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.35);

    margin: -25px 3px;
    position: absolute;
  }

  .btntop p {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #fff;
    margin: 5px;
  }

  .ground {
    width: 240px;
    height: 287px;
    border-radius: 26px;
    background: #4a5573;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0);
    margin-top: 25px;
    margin-left: 50px;
    position: relative;
    z-index: 100;
  }

  .top {
    display: flex;
    height: 20px;
  }

  .windowComponents {
    margin-top: -10px;
  }

  .windowComponents .circle {
    r: 7px;
  }

  .red {
    cx: 10px;
    cy: 9px;
  }

  .yellow {
    cx: 31px;
    cy: 9px;
  }

  .green {
    cx: 52px;
    cy: 9px;
  }

  .main {
    /* width: 206.82px;
    height: 213.66px; */
    width: 206.82px;
    height: 213.66px;
    border-radius: 16px;
    background: #959fba;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
    position: relative;
    /* top: 15px; */
    top: 3px;
    margin-left: 3px;
  }

  .button {
    height: 204px;
  }

  .buttomBase {
    width: 167px;
    height: 44px;
    border-radius: 12px;
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.44);
    position: relative;
    margin: -120px 25px;
  }

  .buttonTop {
    width: 167px;
    height: 44px;
    border-radius: 9px;
    background: #9f6161;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    position: absolute;
    margin: 82px 31px;
  }

  .holyScreen {
    width: 295px;
    height: 258px;
    border-radius: 46px;
    background: #fff;
    opacity: 0.03;
    margin: -301px 20px;
  }

  .progressbar {
    width: 160px;
    height: 17px;
    border-radius: 8.5px;
    background: #959fba;
    position: relative;
    margin: 240px 77px;
  }
  .progressstatus {
    width: 136px;
    height: 13px;
    border-radius: 6.5px;
    background: #2b3657;
    margin: 2px 2px 5px;
    z-index: 100;
    position: absolute;
  }

  .starsContainer {
    width: 157.13px;
    height: 16.74px;
    position: absolute;
    z-index: 100;
    margin: -320px 67px;
  }
  .starsContainer .star {
    width: 17.49px;
    height: 16.74px;
  }

  .pointer {
    width: 72.5px;
    height: 116px;
    margin: 20px 177px;
    position: absolute;
    z-index: 100;
    opacity: "0%";
  }

  .secondsection {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
    margin-top: -65px;
  }

  .secondsection .secondLabel {
    color: #9f6161;
  }
  .firstLabel,
  .secondLabel {
    margin-left: 15px;
  }

  .screenInSecond {
    height: 25px;
    width: 288px;
    background: #fff;
    opacity: 0.02;
    margin-left: 15px;
  }

  .section2Title {
    margin-left: 15px;
  }

  .section2Title h2 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 27px;
    text-align: left;
    color: #fff;
  }

  .section2Title p {
    margin-top: 26px;
    width: 273px;
    height: 328px;
    font-family: "Helvetica Neue";
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: justify;
    color: #fff;
    opacity: 0.64;
  }

  .sub {
    margin-top: 160px;
  }

  .mobileText {
    display: none;
  }

  .section2graph1 {
    width: 146px;
    height: 172px;
    border-radius: 13px;
    background: transparent;
    /* box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.16); */
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.05);
    margin-top: -190px;
    margin-left: 50%;
    font-size: 29px;
  }

  .webTrafficCon {
    margin-left: 20px;
    margin-top: 52px;
    z-index: 100;
    width: 88.88px;
    height: 36.81px;
  }

  .webTraffic {
    fill: none;
    stroke: #fff;
    stroke-width: 4px;
    opacity: 0.644;
    margin-left: 122px;
  }

  .trafficCount {
    margin-left: 15px;
    margin-top: 10%;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #fff;
  }
  .trafficCountName {
    margin-left: 15px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #fff;
  }

  .section3graph1 {
    width: 127px;
    height: 150px;
    border-radius: 14px;
    background: #7b86a2;
    box-shadow: 12px 30px 6px rgba(0, 0, 0, 0.16);
    z-index: 100;
    position: absolute;
    margin-top: -200px;
    margin-left: 15%;
    font-size: 29px;
  }

  .conversionCount {
    margin-left: 15px;
    margin-top: 10%;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #fff;
  }
  .conversionCountName {
    margin-left: 15px;
    margin-left: 15px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #fff;
  }
  .webConversion {
    width: 102.7px;
    height: 40.34px;
    margin-left: 15px;
    margin-top: 32px;
    z-index: 100;
  }

  /* Third Section */
  .webarchitecture {
    position: relative;
    /* margin-right: 420px; */
    margin-left: 35px;
  }

  /* For webarchitecture only mobile  */
  .infrastructureWhole {
    width: 253.53px;
    height: 274px;
  }

  .lightLayout {
    width: 73px;
    height: 73px;
    margin-left: 200px;
  }

  .textsection {
    position: absolute;
    margin-left: 40px;
    top: 2340px;
  }

  .textsection p {
    text-align: justify;
    margin-right: 250px;
    margin-top: 30px;
    color: #fff;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    width: 240px;
    height: 53px;
    word-break: break-all;
  }

  .section3title {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #9f6161;
  }

  .section3bgtitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
  }

  .section3bgSubtitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
    margin-top: 5px;
  }

  .fourthSection {
    margin-top: 270px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 59px;
    text-align: center;
    color: #fff;
  }

  .fourthTitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    color: #9f6161;
  }

  .fourthSubTitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    color: #7b86a2;
    margin-top: 15px;
  }

  .serviceSection {
    margin-top: -78px;
  }

  .ads,
  .uxui,
  .dev {
    width: 240px;
    height: 219px;
    border-radius: 40px;
    background: #131a2f;
    box-shadow: 5px 12px 6px rgba(0, 0, 0, 0.16);
  }

  .ads svg {
    width: 126.4px;
    height: 104.56px;
    margin-top: 20px;
  }

  .uxui svg {
    width: 64.31px;
    height: 90.03px;
    margin-top: 20px;
  }

  .dev svg {
    width: 125.3px;
    height: 83.53px;
    margin-top: 30px;
  }

  .adsTitle,
  .uxuiTitle,
  .devTitle {
    margin-top: 15px;
  }

  .adsTitle,
  .adsTitle2,
  .uxuiTitle,
  .uxuiTitle2,
  .devTitle,
  .devTitle2 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 19px;
    text-align: center;
    color: #9f6161;
  }

  .fifthSection {
    margin-top: 990px;
    color: #fff;
    perspective: 1000px;
  }

  .fifthSection h3 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 25px;
    text-align: center;
    color: #fff;
  }

  .fifthSection h5 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #fff;
  }

  .groundSC {
    margin-top: 20px;
    height: 296px;
    width: 274px;
    position: relative;
  }

  .tapGesture {
    position: absolute;
  }

  .tapGround1 {
    position: absolute;
    top: 360px;
  }

  .section5Title {
    position: absolute;
    top: 450px;
    width: 457px;
  }

  .section5Title {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 23px;
    text-align: left;
    color: #fff;
  }

  .section5Title p {
    width: 261px;
    height: 55px;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    text-align: justify;
    color: #fff;
    opacity: 0.64;
    margin-top: 26px;
  }
  .tapGroundLine {
    z-index: 100;
  }

  .sixthSection {
    margin-top: 379px;
  }

  .sixthSection h1 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 25px;
    color: #a4abc0;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: left;
  }

  .sixthsectionContainer {
    margin-top: 85px;
  }

  .sevenSection {
    margin-top: 550px;
  }

  .sevenSection p {
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 25px;
    line-height: 60px;
    text-align: justify;
    color: #fff;
    opacity: 0.51;
  }

  .contactGround {
    width: 324px;
    height: 335px;
    border-radius: 26px;
    background: #131a2f;
    box-shadow: 5px 12px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 64px;
  }

  .closeTabs {
    margin-right: 426px;
    margin-top: 25px;
  }

  .avatar {
    margin-right: 25px;
    margin-top: 29px;
    width: 117.24px;
    height: 110.62px;
  }

  .actionDescription {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 26px;
    text-align: center;
    color: #fff;
    opacity: 0.64;
    margin-top: 23px;
  }
  .callTActions {
    margin-top: 27px;
  }

  .messageBtn {
    width: 126px;
    height: 46px;
    border-radius: 11px;
    background: #131a2f;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    margin-left: 25px;
  }
  .messageIcon,
  .callIcon {
    margin-top: 12.4px;
    width: 31.55px;
    height: 21.84px;
  }

  .callBtn {
    width: 126px;
    height: 46px;
    border-radius: 11px;
    background: #9f6161;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-right: 75px;
  }

  .Impressum p {
    width: 300px;
    height: 129px;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 25px;
    line-height: 60px;
    text-align: left;
    color: #fff;
    opacity: 0.51;
  }

  .Impressum {
    margin-bottom: 13px;
  }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
/* Portrait and Landscape */
@media only screen and (max-width: 320px) {
  .mobile {
    display: block;
    width: auto;
    height: 70px;
    background: transparent;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 28px;
    text-align: center;
    color: #fff;
    margin: 55px 25px;
    margin-bottom: 8px;
  }
  .mobile p {
    color: #fff;
  }
  .mobile span {
    color: #9f6161;
  }

  .desktop {
    display: none;
  }

  .logo {
    font-weight: normal;
    font-size: 14px;
    text-align: left;
  }

  .ueb,
  .ref {
    display: none;
  }

  .btnbase {
    width: 104px;
    height: 28px;
    border-radius: 6px;
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(3.327169895172119px);
    --webkit-backdrop-filter: blur(3.327169895172119px);
    background-color: rgba(255, 255, 255, 0.36);
    /* (plus shape's fill blended on top as a separate layer with 43.55% opacity) */

    position: relative;
  }

  .btntop {
    width: 103px;
    height: 29px;
    border-radius: 6px;
    background: #131a2f;
    box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.35);

    margin: -25px 3px;
    position: absolute;
  }

  .btntop p {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #fff;
    margin: 5px;
  }

  .ground {
    width: 240px;
    height: 287px;
    border-radius: 26px;
    background: #4a5573;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0);
    margin-top: 25px;
    margin-left: 20px;
    position: relative;
    z-index: 100;
  }

  .top {
    display: flex;
    height: 20px;
  }

  .windowComponents {
    margin-top: -10px;
  }

  .windowComponents .circle {
    r: 7px;
  }

  .red {
    cx: 10px;
    cy: 9px;
  }

  .yellow {
    cx: 31px;
    cy: 9px;
  }

  .green {
    cx: 52px;
    cy: 9px;
  }

  .main {
    /* width: 206.82px;
      height: 213.66px; */
    width: 206.82px;
    height: 213.66px;
    border-radius: 16px;
    background: #959fba;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
    position: relative;
    /* top: 15px; */
    top: 3px;
    margin-left: 3px;
  }

  .button {
    height: 204px;
  }

  .buttomBase {
    width: 167px;
    height: 44px;
    border-radius: 12px;
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.44);
    position: relative;
    margin: -120px 25px;
  }

  .buttonTop {
    width: 167px;
    height: 44px;
    border-radius: 9px;
    background: #9f6161;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    position: absolute;
    margin: 82px 31px;
  }

  .holyScreen {
    width: 295px;
    height: 258px;
    border-radius: 26px;
    background: #fff;
    opacity: 0.03;
    margin: -301px -13px;
  }

  .progressbar {
    width: 160px;
    height: 17px;
    border-radius: 8.5px;
    background: #959fba;
    position: relative;
    margin: 240px 49px;
  }
  .progressstatus {
    width: 136px;
    height: 13px;
    border-radius: 6.5px;
    background: #2b3657;
    margin: 2px 2px 5px;
    z-index: 100;
    position: absolute;
  }

  .starsContainer {
    width: 157.13px;
    height: 16.74px;
    position: absolute;
    z-index: 100;
    margin: -320px 41px;
  }
  .starsContainer .star {
    width: 17.49px;
    height: 16.74px;
  }

  .pointer {
    width: 72.5px;
    height: 116px;
    margin: 20px 177px;
    position: absolute;
    z-index: 100;
    opacity: "0%";
  }

  .secondsection {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
    margin-top: -65px;
  }

  .secondsection .secondLabel {
    color: #9f6161;
  }
  .firstLabel,
  .secondLabel {
    margin-left: 15px;
  }

  .screenInSecond {
    height: 25px;
    width: 246px;
    background: #fff;
    opacity: 0.02;
    margin-left: 15px;
  }

  .section2Title {
    margin-left: 15px;
  }

  .section2Title h2 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 27px;
    text-align: left;
    color: #fff;
  }

  .section2Title p {
    margin-top: 26px;
    width: auto;
    height: 328px;
    font-family: "Noto Sans";
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: justify;
    color: #fff;
    opacity: 0.64;
  }

  .sub {
    margin-top: 160px;
  }

  .mobileText {
    display: none;
  }

  .section2graph1 {
    width: 146px;
    height: 172px;
    border-radius: 13px;
    background: transparent;
    /* box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.16); */
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.05);
    margin-top: -190px;
    margin-left: 50%;
    font-size: 29px;
  }

  .webTrafficCon {
    margin-left: 20px;
    margin-top: 52px;
    z-index: 100;
    width: 88.88px;
    height: 36.81px;
  }

  .webTraffic {
    fill: none;
    stroke: #fff;
    stroke-width: 4px;
    opacity: 0.644;
    margin-left: 122px;
  }

  .trafficCount {
    margin-left: 15px;
    margin-top: 10%;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #fff;
  }
  .trafficCountName {
    margin-left: 15px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #fff;
  }

  .section3graph1 {
    width: 127px;
    height: 150px;
    border-radius: 14px;
    background: #7b86a2;
    box-shadow: 12px 30px 6px rgba(0, 0, 0, 0.16);
    z-index: 100;
    position: absolute;
    margin-top: -200px;
    margin-left: 15%;
    font-size: 29px;
  }

  .conversionCount {
    margin-left: 15px;
    margin-top: 10%;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #fff;
  }
  .conversionCountName {
    margin-left: 15px;
    margin-left: 15px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #fff;
  }
  .webConversion {
    width: 102.7px;
    height: 40.34px;
    margin-left: 15px;
    margin-top: 32px;
    z-index: 100;
  }

  /* Third Section */
  .webarchitecture {
    position: relative;
    /* margin-right: 420px; */
    margin-left: 20px;
  }

  /* For webarchitecture only mobile  */
  .infrastructureWhole {
    width: 253.53px;
    height: 274px;
    margin-top: -52px;
  }

  .lightLayout {
    width: 73px;
    height: 73px;
    margin-left: 200px;
  }

  .textsection {
    position: absolute;
    margin-left: 20px;
    top: 2340px;
  }

  .textsection p {
    text-align: justify;
    margin-right: 250px;
    margin-top: 30px;
    color: #fff;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    width: 240px;
    height: 53px;
    word-break: break-all;
  }

  .section3title {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #9f6161;
  }

  .section3bgtitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
  }

  .section3bgSubtitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
    margin-top: 5px;
  }

  .fourthSection {
    margin-top: 270px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 59px;
    text-align: center;
    color: #fff;
  }

  .fourthTitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    color: #9f6161;
  }

  .fourthSubTitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    color: #7b86a2;
    margin-top: 15px;
  }

  .serviceSection {
    margin-top: -78px;
  }

  .ads,
  .uxui,
  .dev {
    width: 240px;
    height: 219px;
    border-radius: 40px;
    background: #131a2f;
    box-shadow: 5px 12px 6px rgba(0, 0, 0, 0.16);
  }

  .ads svg {
    width: 126.4px;
    height: 104.56px;
    margin-top: 20px;
  }

  .uxui svg {
    width: 64.31px;
    height: 90.03px;
    margin-top: 20px;
  }

  .dev svg {
    width: 125.3px;
    height: 83.53px;
    margin-top: 30px;
  }

  .adsTitle,
  .uxuiTitle,
  .devTitle {
    margin-top: 15px;
  }

  .adsTitle,
  .adsTitle2,
  .uxuiTitle,
  .uxuiTitle2,
  .devTitle,
  .devTitle2 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 19px;
    text-align: center;
    color: #9f6161;
  }

  .fifthSection {
    margin-top: 990px;
    color: #fff;
    perspective: 1000px;
  }

  .fifthSection h3 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 25px;
    text-align: center;
    color: #fff;
  }

  .fifthSection h5 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #fff;
  }

  .groundSC {
    margin-top: 20px;
    height: 296px;
    width: 274px;
    position: relative;
  }

  .tapGesture {
    position: absolute;
  }

  .tapGround1 {
    position: absolute;
    top: 360px;
  }

  .section5Title {
    position: absolute;
    top: 450px;
    width: 457px;
  }

  .section5Title {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 23px;
    text-align: left;
    color: #fff;
  }

  .section5Title p {
    width: 261px;
    height: 55px;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    text-align: justify;
    color: #fff;
    opacity: 0.64;
    margin-top: 26px;
  }
  .tapGroundLine {
    z-index: 100;
  }

  .sixthSection {
    margin-top: 379px;
  }

  .sixthSection h1 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 25px;
    color: #a4abc0;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: left;
  }

  .sixthsectionContainer {
    margin-top: 85px;
  }

  .sevenSection {
    margin-top: 550px;
  }

  .sevenSection p {
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 25px;
    line-height: 60px;
    text-align: justify;
    color: #fff;
    opacity: 0.51;
  }

  .contactGround {
    width: 324px;
    height: 335px;
    border-radius: 26px;
    background: #131a2f;
    box-shadow: 5px 12px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 64px;
  }

  .closeTabs {
    margin-right: 426px;
    margin-top: 25px;
  }

  .avatar {
    margin-right: 25px;
    margin-top: 29px;
    width: 117.24px;
    height: 110.62px;
  }

  .actionDescription {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 26px;
    text-align: center;
    color: #fff;
    opacity: 0.64;
    margin-top: 23px;
  }
  .callTActions {
    margin-top: 27px;
  }

  .messageBtn {
    width: 126px;
    height: 46px;
    border-radius: 11px;
    background: #131a2f;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    margin-left: 25px;
  }
  .messageIcon,
  .callIcon {
    margin-top: 12.4px;
    width: 31.55px;
    height: 21.84px;
  }

  .callBtn {
    width: 126px;
    height: 46px;
    border-radius: 11px;
    background: #9f6161;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-right: 75px;
  }

  .Impressum p {
    width: 300px;
    height: 129px;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 25px;
    line-height: 60px;
    text-align: left;
    color: #fff;
    opacity: 0.51;
  }

  .Impressum {
    margin-bottom: 13px;
  }
}

/* Iphone 6,7,8 plus */
@media only screen and (min-width: 380px) and (max-width: 430px) {
  .mobile {
    display: block;
    width: auto;
    height: 70px;
    background: transparent;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 28px;
    text-align: center;
    color: #fff;
    margin: 55px 25px;
    margin-bottom: 8px;
  }
  .mobile p {
    color: #fff;
  }
  .mobile span {
    color: #9f6161;
  }

  .desktop {
    display: none;
  }

  .logo {
    font-weight: normal;
    font-size: 14px;
    text-align: left;
  }

  .ueb,
  .ref {
    display: none;
  }

  .btnbase {
    width: 104px;
    height: 28px;
    border-radius: 6px;
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(3.327169895172119px);
    --webkit-backdrop-filter: blur(3.327169895172119px);
    background-color: rgba(255, 255, 255, 0.36);
    /* (plus shape's fill blended on top as a separate layer with 43.55% opacity) */

    position: relative;
  }

  .btntop {
    width: 103px;
    height: 29px;
    border-radius: 6px;
    background: #131a2f;
    box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.35);

    margin: -25px 3px;
    position: absolute;
  }

  .btntop p {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #fff;
    margin: 5px;
  }

  .ground {
    width: 240px;
    height: 287px;
    border-radius: 26px;
    background: #4a5573;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0);
    margin-top: 25px;
    margin-left: 65px;
    position: relative;
    z-index: 100;
  }

  .top {
    display: flex;
    height: 20px;
  }

  .windowComponents {
    margin-top: -10px;
  }

  .windowComponents .circle {
    r: 7px;
  }

  .red {
    cx: 10px;
    cy: 9px;
  }

  .yellow {
    cx: 31px;
    cy: 9px;
  }

  .green {
    cx: 52px;
    cy: 9px;
  }

  .main {
    /* width: 206.82px;
    height: 213.66px; */
    width: 206.82px;
    height: 213.66px;
    border-radius: 16px;
    background: #959fba;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));
    position: relative;
    /* top: 15px; */
    top: 3px;
    margin-left: 3px;
  }

  .button {
    height: 204px;
  }

  .buttomBase {
    width: 167px;
    height: 44px;
    border-radius: 12px;
    background: transparent;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.44);
    position: relative;
    margin: -120px 25px;
  }

  .buttonTop {
    width: 167px;
    height: 44px;
    border-radius: 9px;
    background: #9f6161;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    position: absolute;
    margin: 82px 31px;
  }

  .holyScreen {
    width: 295px;
    height: 258px;
    border-radius: 46px;
    background: #fff;
    opacity: 0.03;
    margin: -301px 35px;
  }

  .progressbar {
    width: 160px;
    height: 17px;
    border-radius: 8.5px;
    background: #959fba;
    position: relative;
    margin: 240px 91px;
  }
  .progressstatus {
    width: 136px;
    height: 13px;
    border-radius: 6.5px;
    background: #2b3657;
    margin: 2px 2px 5px;
    z-index: 100;
    position: absolute;
  }

  .starsContainer {
    width: 157.13px;
    height: 16.74px;
    position: absolute;
    z-index: 100;
    margin: -320px 85px;
  }
  .starsContainer .star {
    width: 17.49px;
    height: 16.74px;
  }

  .pointer {
    width: 72.5px;
    height: 116px;
    margin: 20px 177px;
    position: absolute;
    z-index: 100;
    opacity: "0%";
  }

  .secondsection {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
    margin-top: -65px;
    margin-right: 12px;
  }

  .secondsection .secondLabel {
    color: #9f6161;
  }
  .firstLabel,
  .secondLabel {
    margin-left: 35px;
  }

  .screenInSecond {
    height: 25px;
    width: 288px;
    background: #fff;
    opacity: 0.02;
    margin-left: 25px;
  }

  .section2Title {
    margin-left: 35px;
  }

  .section2Title h2 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 21px;
    line-height: 27px;
    text-align: left;
    color: #fff;
  }

  .section2Title p {
    margin-top: 26px;
    width: 273px;
    height: 328px;
    font-family: "Noto Sans";
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    text-align: justify;
    color: #fff;
    opacity: 0.64;
  }

  .sub {
    margin-top: 160px;
  }

  .mobileText {
    display: none;
  }

  .section2graph1 {
    width: 146px;
    height: 172px;
    border-radius: 13px;
    background: transparent;
    /* box-shadow: 0px 12px 6px rgba(0, 0, 0, 0.16); */
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.05);
    margin-top: -190px;
    margin-left: 50%;
    font-size: 29px;
  }

  .webTrafficCon {
    margin-left: 20px;
    margin-top: 52px;
    z-index: 100;
    width: 88.88px;
    height: 36.81px;
  }

  .webTraffic {
    fill: none;
    stroke: #fff;
    stroke-width: 4px;
    opacity: 0.644;
    margin-left: 122px;
  }

  .trafficCount {
    margin-left: 15px;
    margin-top: 10%;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #fff;
  }
  .trafficCountName {
    margin-left: 15px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #fff;
  }

  .section3graph1 {
    width: 127px;
    height: 150px;
    border-radius: 14px;
    background: #7b86a2;
    box-shadow: 12px 30px 6px rgba(0, 0, 0, 0.16);
    z-index: 100;
    position: absolute;
    margin-top: -200px;
    margin-left: 15%;
    font-size: 29px;
  }

  .conversionCount {
    margin-left: 15px;
    margin-top: 10%;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    color: #fff;
  }
  .conversionCountName {
    margin-left: 15px;
    margin-left: 15px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    color: #fff;
  }
  .webConversion {
    width: 102.7px;
    height: 40.34px;
    margin-left: 15px;
    margin-top: 32px;
    z-index: 100;
  }

  /* Third Section */
  .webarchitecture {
    position: relative;
    /* margin-right: 420px; */
    margin-left: 35px;
  }

  /* For webarchitecture only mobile  */
  .infrastructureWhole {
    width: 253.53px;
    height: 274px;
  }

  .lightLayout {
    width: 73px;
    height: 73px;
    margin-left: 200px;
  }

  .textsection {
    position: absolute;
    margin-left: 40px;
    top: 2340px;
  }

  .textsection p {
    text-align: justify;
    margin-right: 250px;
    margin-top: 30px;
    color: #fff;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #fff;
    width: 75%;
    height: auto;
    word-break: normal;
  }

  .section3title {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: #9f6161;
  }

  .section3bgtitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
  }

  .section3bgSubtitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: left;
    color: #fff;
    margin-top: -205px;
  }

  .fourthSection {
    margin-top: 270px;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 59px;
    text-align: center;
    color: #fff;
  }

  .fourthTitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    color: #9f6161;
  }

  .fourthSubTitle {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    color: #7b86a2;
    margin-top: 15px;
  }

  .serviceSection {
    margin-top: -78px;
    margin-left: 15px;
  }

  .ads,
  .uxui,
  .dev {
    width: 240px;
    height: 219px;
    border-radius: 40px;
    background: #131a2f;
    box-shadow: 5px 12px 6px rgba(0, 0, 0, 0.16);
  }

  .ads svg {
    width: 126.4px;
    height: 104.56px;
    margin-top: 20px;
  }

  .uxui svg {
    width: 64.31px;
    height: 90.03px;
    margin-top: 20px;
  }

  .dev svg {
    width: 125.3px;
    height: 83.53px;
    margin-top: 30px;
  }

  .adsTitle,
  .uxuiTitle,
  .devTitle {
    margin-top: 15px;
  }

  .adsTitle,
  .adsTitle2,
  .uxuiTitle,
  .uxuiTitle2,
  .devTitle,
  .devTitle2 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 19px;
    text-align: center;
    color: #9f6161;
  }

  .fifthSection {
    margin-top: 990px;
    color: #fff;
    perspective: 1000px;
  }

  .fifthSection h3 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 25px;
    text-align: center;
    color: #fff;
  }

  .fifthSection h5 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #fff;
  }

  .groundSC {
    margin-top: 20px;
    height: 296px;
    width: 274px;
    position: relative;
  }

  .tapGesture {
    position: absolute;
  }

  .tapGround1 {
    position: absolute;
    top: 360px;
  }

  .section5Title {
    position: absolute;
    top: 450px;
    width: 457px;
  }

  .section5Title {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 23px;
    text-align: left;
    color: #fff;
  }

  .section5Title p {
    width: 261px;
    height: 55px;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    text-align: justify;
    color: #fff;
    opacity: 0.64;
    margin-top: 26px;
  }
  .tapGroundLine {
    z-index: 100;
  }

  .sixthSection {
    margin-top: 379px;
  }

  .sixthSection h1 {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 25px;
    color: #a4abc0;
    /* Note: currently only Safari supports backdrop-filter */
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: left;
  }

  .sixthsectionContainer {
    margin-top: 85px;
  }

  .sevenSection {
    margin-top: 550px;
  }

  .sevenSection p {
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 25px;
    line-height: 60px;
    text-align: justify;
    color: #fff;
    opacity: 0.51;
  }

  .contactGround {
    width: 324px;
    height: 335px;
    border-radius: 26px;
    background: #131a2f;
    box-shadow: 5px 12px 6px rgba(0, 0, 0, 0.16);
    margin-bottom: 64px;
  }

  .closeTabs {
    margin-right: 426px;
    margin-top: 25px;
  }

  .avatar {
    margin-right: 25px;
    margin-top: 29px;
    width: 117.24px;
    height: 110.62px;
  }

  .actionDescription {
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    font-size: 26px;
    text-align: center;
    color: #fff;
    opacity: 0.64;
    margin-top: 23px;
  }
  .callTActions {
    margin-top: 27px;
  }

  .messageBtn {
    width: 126px;
    height: 46px;
    border-radius: 11px;
    background: #131a2f;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    margin-left: 25px;
  }
  .messageIcon,
  .callIcon {
    margin-top: 12.4px;
    width: 31.55px;
    height: 21.84px;
  }

  .callBtn {
    width: 126px;
    height: 46px;
    border-radius: 11px;
    background: #9f6161;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-right: 75px;
  }

  .Impressum p {
    width: 300px;
    height: 129px;
    font-family: "Helvetica Neue";
    font-weight: normal;
    font-size: 25px;
    line-height: 60px;
    text-align: left;
    color: #fff;
    opacity: 0.51;
  }

  .Impressum {
    margin-bottom: 13px;
  }
}
